body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-table {
    cursor: default;
}

.rowNormal {
    background-color: #FFFFFF;
}

.rowHoliday {
    background-color: #BFBFBF;
}

.rowCompiled {
    background-color: #F6FAF6;
}

.rowCompiledOk {
    background-color: #D6EED6;
}

.rowCompiledWarning {
    background-color: #F7BCBF;
}

.rowCompiledSuperOk {
    background-color: #AAD4AA;
}

.cursor-pointer {
    cursor: pointer;
}

.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th {
    text-align: center;
    font-weight: 600;
    overflow-wrap: anywhere;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
    background-color: initial !important;
}

.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr.selectedDay > td {
    color: #1899FF;
    border-top: 5px solid #1899FF;
    border-Bottom: 5px solid #1899FF;
}

.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr.selectedDay > td:first-child {
    padding-left: 3px;
    border-left: 5px solid #1899FF;
}

.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr.selectedDay > td:last-child {
    margin-right: 23px;
    padding-right: 4px;
    border-right: 5px solid #1899FF;
}

.ant-form-item .ant-form-item-label {
    padding: 0
}

.ant-avatar {
    border-radius: 0;
}

tr.daysOffAndLeaveReportTableGreen > td:nth-last-child(2),
tr.daysOffAndLeaveReportTableGreen:hover > td:nth-last-child(2) {
    background-color: #D6EED6;
}

tr.daysOffAndLeaveReportTableYellow > td:nth-last-child(2),
tr.daysOffAndLeaveReportTableYellow:hover > td:nth-last-child(2) {
    background-color: #FFF7B3;
}

tr.daysOffAndLeaveReportTableRed > td:nth-last-child(2),
tr.daysOffAndLeaveReportTableRed:hover > td:nth-last-child(2) {
    background-color: #F7BCBF;
}

tr.daysOffAndLeaveReportTableDarkRed > td:nth-last-child(2),
tr.daysOffAndLeaveReportTableDarkRed:hover > td:nth-last-child(2) {
    background-color: #F0787D;
}

.ant-table.ant-table-small .ant-table-tbody > tr.daysOffAndLeaveReportTableGreen > td:last-child,
.ant-table.ant-table-small .ant-table-tbody > tr.daysOffAndLeaveReportTableYellow > td:last-child,
.ant-table.ant-table-small .ant-table-tbody > tr.daysOffAndLeaveReportTableRed > td:last-child {
    padding: 4px 4px 0;
    border-bottom-width: 0;
}

tr.timesheetDaysOffAndLeaveReportTableGreen > td:last-child,
tr.timesheetDaysOffAndLeaveReportTableGreen:hover > td:last-child {
    background-color: #D6EED6;
}

tr.timesheetDaysOffAndLeaveReportTableYellow > td:last-child,
tr.timesheetDaysOffAndLeaveReportTableYellow:hover > td:last-child {
    background-color: #FFF7B3;
}

tr.timesheetDaysOffAndLeaveReportTableRed > td:last-child,
tr.timesheetDaysOffAndLeaveReportTableRed:hover > td:last-child {
    background-color: #F7BCBF;
}

tr.timesheetDaysOffAndLeaveReportTableDarkRed > td:last-child,
tr.timesheetDaysOffAndLeaveReportTableDarkRed:hover > td:last-child {
    background-color: #F0787D;
}

tr.summary {
    background-color: #FAFAFA;
    font-weight: bold;
}